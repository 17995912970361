import React from "react"
import Layout from "../components/Theme/layout"

const BlogPage = () => {
  return( 
    <Layout>
      <h1>Blog Page</h1>
    </Layout>
    )
}

export default BlogPage